// Define breakpoints for responsive design
const breakpoints = {
	sm: 415,
	md: 768,
	lg: 1280,
	xl: 1440,
	"2xl": 1920,
	"3xl": 3840,
};

// Initialize event listeners when the document is ready
$(document).ready(function () {
	$(".mobile-has-children > a").on("click", toggleMobileSubmenu);
	$("#primary-navigation--button").on("click keypress", function (e) {
		if (e.type === "click" || (e.type === "keypress" && e.which === 13)) {
			toggleNavigation(breakpoints);
		}
	});
	$("#back-to-top").on("click", scrollToTop);
	$(".accordion").on("click", toggleAccordion);

	$("body").on("click", ".hcp-modal-link", function( event ) {
		if (!$(this).attr("href")) {
			return;
		}
		let clickedHref = $(this).attr("href");
		$("#hcp-modal .hcp-link").attr("href", clickedHref);
		$("#hcp-modal").addClass("open");
		event.preventDefault();
		return false;
	});

	$("body").on("click", ".close-hcp-modal", function( event ) {
		$("#hcp-modal").removeClass("open");
		event.preventDefault();
		return false;
	});

	$("body").on("click", "#hcp-modal .hcp-link", function( event ) {
		setTimeout( function() {
			$("#hcp-modal .hcp-link").attr("href", "#");
		}, 500);
		$("#hcp-modal").removeClass("open");
	});

	$("body") .on("click", "a:not(#external-link-modal--link,.no-link-modal,.no-external)", function (
		event
	) {
		if (!$(this).attr("href")) {
			return;
		}
		let clickedHref = $(this).attr("href");

		// Check if the href starts with 'http', 'https', or '//'
		if (clickedHref.startsWith("http") || clickedHref.startsWith("//")) {
			if (!clickedHref.startsWith("https://sanofivaccines.co.uk") && !clickedHref.startsWith("https://sanofi.co.uk") && !clickedHref.startsWith("https://www.sanofi.co.uk")) {
				event.preventDefault(); // Prevent the link from being followed
				openLinkModal(clickedHref);
			}
		}
	});

	$(".ig-modal").click(function (event) {
		let clickTarget = $(event.target);
		if (clickTarget.is(".ig-modal")) {
			let modalId = $(this).attr('id');
			closeModal(modalId);
		}
	});

	// Listener for opening a modal
	$('[data-toggle="modal"]').click(function () {
		var modalId = $(this).data("target");
		openModal(modalId);
	});

	// Listener for closing a modal
	$(".modal--closer, .external-link-modal--closer").click(function () {
		var modalId = $(this).closest(".ig-modal").attr("id");
		closeModal(modalId);
	});

	// Close modal when clicking outside of the modal content
	$(".modal").click(function (event) {
		if ($(event.target).is(".modal")) {
			closeModal($(this).attr("id"));
		}
	});

	$(".slider").each(function () {
		console.log($(this).attr("id"));
		$(this).slick({
			dots: true,
			appendDots: "#" + $(this).attr("id") + "-navigation",
			appendArrows: "#" + $(this).attr("id") + "-navigation",
		});
	});

	$(".custom-select").click(function (event) {
		if (!$(this).hasClass("disabled")) {
			// Close any currently open custom selects
			$(".custom-select.active")
				.not(this)
				.removeClass("active")
				.attr("aria-expanded", "false");

			// Toggle the active state of the clicked custom select
			$(this).toggleClass("active");
			$(this).attr("aria-expanded", $(this).hasClass("active"));

			// If any custom select is now active, set up the body click event listener
			if ($(".custom-select.active").length > 0) {
				// Attach a one-off event listener to body
				$("body").one("click.customSelect", function (e) {
					// Close all active custom selects
					$(".custom-select.active")
						.removeClass("active")
						.attr("aria-expanded", "false");
				});

				event.stopPropagation();
			} else {
				// Remove the event listener if no selects are active
				$("body").off("click.customSelect");
			}
		}
	});

	$(".custom-select").on("click", "ul li", function (event) {
		var text = $(this).text();
		$(this).closest(".custom-select").find(".select-text").text(text);
		$(this)
			.siblings(".active")
			.removeClass("active")
			.attr("aria-selected", "false");
		$(this).addClass("active").attr("aria-selected", "true");
		$(this)
			.closest(".custom-select")
			.removeClass("active")
			.attr("aria-expanded", "false");
		event.stopPropagation();
	});

	// Keyboard navigation for the custom select
	$(".custom-select").on("keydown", function (event) {
		if (!$(this).hasClass("disabled")) {
			const listbox = $(this).find("ul");
			const options = listbox.find("li");
			const activeOption = options.filter(".active");
			let focusedOption = options.filter(":focus");

			switch (event.key) {
				case "ArrowDown":
					event.preventDefault();
					if (!$(this).hasClass("active")) {
						$(this).addClass("active").attr("aria-expanded", "true");
						options.first().focus();
					} else if (
						focusedOption.length === 0 ||
						focusedOption.is(options.last())
					) {
						options.first().focus();
					} else {
						focusedOption.next().focus();
					}
					break;

				case "ArrowUp":
					event.preventDefault();
					if ($(this).hasClass("active")) {
						if (
							focusedOption.length === 0 ||
							focusedOption.is(options.first())
						) {
							options.last().focus();
						} else {
							focusedOption.prev().focus();
						}
					}
					break;

				case "Enter":
					event.preventDefault();
					if ($(this).hasClass("active") && focusedOption.length > 0) {
						activeOption
							.removeClass("active")
							.attr("aria-selected", "false");
						focusedOption
							.addClass("active")
							.attr("aria-selected", "true");
						$(this).find(".select-text").text(focusedOption.text());
						$(this).removeClass("active").attr("aria-expanded", "false");
					} else {
						$(this).addClass("active").attr("aria-expanded", "true");
						options.first().focus();
					}
					break;

				case "Escape":
					if ($(this).hasClass("active")) {
						$(this).removeClass("active").attr("aria-expanded", "false");
						$(this).focus();
					}
					break;
			}
		}
	});

	$(".custom-select").on("focusout", function (event) {
		// Use setTimeout to delay the check until after the new focus is established
		setTimeout(() => {
			// Check if the newly focused element is outside the custom select
			if (!$.contains(this, document.activeElement)) {
				$(this).removeClass("active").attr("aria-expanded", "false");
			}
		}, 0);
	});
	var generateLinkTimer;
	$(".generate-link").click(function() {
		
		// Clear any existing timeout to prevent overlap
		if (generateLinkTimer) {
			$(this).find('.copy-alert').removeClass('fadeinout');
			clearTimeout(generateLinkTimer);
		}
	
		// Copy the URL of the current page to the clipboard
		navigator.clipboard.writeText(window.location.href).then(() => {
			console.log('Page URL copied to clipboard');
		}).catch(err => {
			console.error('Error in copying text: ', err);
		});

		setTimeout(() => {
			$(this).find('.copy-alert').addClass('fadeinout');
		}, 10);		
	
		generateLinkTimer = setTimeout(() => {
			$(this).find('.copy-alert').removeClass('fadeinout');
		}, 3000);
	});
	
});

function openModal(modalId) {
	$("#" + modalId).fadeIn();
	$("body").addClass("overflow-hidden");
}

function closeModal(modalId) {
	$("#" + modalId).fadeOut();
	$("body").removeClass("overflow-hidden");
}

function openLinkModal(href) {
	$("#external-link-modal--link").attr("href", href);
	openModal('external-link-modal');
}

function toggleAccordion() {
	$(this).find(".accordion-content").slideToggle();
	$(this).find(".accordion-icon").toggleClass("-rotate-180");
}

function scrollToTop() {
	$("html, body").animate({ scrollTop: 0 }, 200, "linear");
}

// Toggles the mobile submenu for elements with class 'mobile-has-children'
function toggleMobileSubmenu(e) {
	e.preventDefault();
	var childUl = $(this).next("ul");
	childUl.slideToggle();
	$(this).closest(".mobile-has-children").toggleClass("open");
}

// Toggles the navigation based on screen size
function toggleNavigation(breakpoints) {
	const width = $(window).width();
	$("#primary-navigation--button").toggleClass("open");

	if (width < breakpoints.md) {
		// Toggle mobile navigation for smaller screens
		$("#mobile-navigation").slideToggle();
		$("#mobile-nav-bg").fadeToggle();
		$("body").toggleClass("overflow-hidden");
	} else {
		// Toggle primary navigation for larger screens
		$("#primary-navigation").slideToggle();
	}
}
